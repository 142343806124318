import axios from "axios";
import { onRequest, onRequestError } from "./requestInterceptor";


// export const BASE_URL = "https://jupiter-dot-supernova-app-qa.appspot.com/rank-tracking/";

// export const SUPERNOVA_BASE_URL = `https://supernova-app-qa.appspot.com/`;

export const BASE_URL = "https://jupiter-dot-supernova-app-uat.appspot.com/rank-tracking/";

export const SUPERNOVA_BASE_URL = `https://supernova-app-uat.appspot.com/`;

export const API = axios.create({
  baseURL: BASE_URL
});

export const SUPERNOVA_API = axios.create({
  baseURL: SUPERNOVA_BASE_URL
});

API.interceptors.request.use(config => onRequest(config, false), onRequestError);
SUPERNOVA_API.interceptors.request.use(config => onRequest(config, true), onRequestError);

export const API_FETCH_NOTIFICATIONS = "notifications";
export const API_FETCH_METADATA = "metadata";
export const API_PROJECT = "projects";
export const API_FETCH_ORGANIZATIONS = "organizations";
export const API_FETCH_ACCESSIBLE_CLIENTS = "organizations-list";
export const API_FETCH_DEPARTMENTS = "departments";
export const API_SHARE = "project-share";
export const API_TEMPLATE_DOWNLOAD = "keyword-template";
export const API_DOWNLOAD = "download-keywords";
export const API_UPLOAD_HISTORY = "upload-history";
export const API_UPLOAD_URL = "bulk-upload-url";
export const API_AUTH = "auth";
export const API_FETCH_FREQUENCY = "frequencies";
export const API_FETCH_ENGINE = "engines";
export const API_FETCH_LANG = "locales";
export const API_TAGS = "project-tags";
export const API_MANUAL_UPLOAD = "manual-edit-keywords";
export const API_NOTIFICATION = "notifications";
export const API_FETCH_DEVICES = "devices";
export const API_EMBED_URL = "embed_url";
export const API_DASHBOARD_SLUG = "dashboard_slug";
export const API_DOWNLOAD_FILE_URL = "download-url";
export const API_FETCH_COUNTRY = "country-list";
export const API_FETCH_LOCATION = "location-list";
export const API_FETCH_LOCATION_TYPE = "location-types";
